<div class="gallery">
    <div class="imagelibrary">
        <div class="imagewrapper" *ngFor="let item of imgdata" (click)="viewDetail(item.hqimg)">
            <div class="imgcont">
                <img class="img" src="{{item.lqimg}}">

            </div>
        </div>
    </div>

    <div class="detailimg" *ngIf="showdetail">
        <div class="outer">
            <div class="side">

            </div>
            <div class="inner">
                <img src="{{detailurl}}" class="img1">
            </div>
            <div class="side">
                <i class="material-icons btnclose" (click)="closeDetail()">
                    close
                </i>

            </div>
        </div>
    </div>
</div>