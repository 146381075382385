import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(private http:HttpClient){

  }
  title = 'fotoboxwebview';
  public imgdata: ImageData[];
  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    setInterval(()=>{
      this.http.get<ImageData[]>("https://fotobox.lorenzwinkler.com/assets/getfotoboximgs.php").toPromise().then((item) => {
        this.imgdata = item;
      });
    }, 3000);
  }
}
