import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-picturegallery',
  templateUrl: './picturegallery.component.html',
  styleUrls: ['./picturegallery.component.scss']
})
export class PicturegalleryComponent implements OnInit {
  @Input() imgdata: ImageData[];
  public showdetail: boolean = false;
  public detailurl: string = "";
  constructor() { }
  viewDetail(url: string) {
    this.detailurl = url;
    this.showdetail = true;
  }
  closeDetail() {
    this.showdetail = false;
  }
  ngOnInit() {
    console.log(this.imgdata);
  }
}
export interface ImageData {
  lqimg: string;
  hqimg: string;
}
